/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable strict */
/* eslint-disable eqeqeq */
/* eslint-disable no-sequences */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import $ from "jquery";

$(document).ready(function () {
  "use strict";
  const e =
    ((s = 0),
      function (e, o) {
        clearTimeout(s), (s = setTimeout(e, o));
      });
  var s;
  $("#sidebar-toggle").on("click", function () {
    $("body").toggleClass("sidebar-hidden");
  }),
    $("#sidebar-collapsed-toggle").on("click", function () {
      !(function () {
        $("body").toggleClass("page-sidebar-collapsed");
        const e = document.querySelector(".page-sidebar .accordion-menu");
        // s = new PerfectScrollbar(e);
        let hasValue = $("body").hasClass("page-sidebar-collapsed");
        //  
        if (hasValue) {
          $(".add-collapse-margin").addClass("collapse-margin");
        } else {
          $(".add-collapse-margin").removeClass("collapse-margin")
        }
      })();
    }),
    $(".close-search").on("click", function () {
      $("body").removeClass("search-show");
    }),
    $("#toggle-search").on("click", function () {
      $("body").addClass("search-show");
    }),
    feather.replace();
  $(".header-search input").on("input", function () {
    var s = $(this).val();
    s.length > 0
      ? ($("body").addClass("search-results-show"),
        $("span.search-input-value").html(s))
      : $("body").removeClass("search-results-show"),
      $(".search-results .search-result-list").fadeOut(1),
      e(function () {
        0 != !$.trim($(".header-search input").val()).length
          ? $(".search-results .search-result-list").fadeOut(1)
          : $(".search-results .search-result-list").fadeIn();
      }, 500);
  }),
    $(document).keyup(function (e) {
      $("body").hasClass("search-results-show") &&
        27 === e.keyCode &&
        $("body").removeClass("search-results-show");
    }),
    $(document).mouseup(function (e) {
      var s = $(".search-results"),
        o = $(".header-search input");
      s.is(e.target) ||
        o.is(e.target) ||
        0 !== s.has(e.target).length ||
        0 !== o.has(e.target).length ||
        $("body").removeClass("search-results-show");
    }),
    $("#closeSearch").on("click", function () {
      $("body").removeClass("search-results-show");
    }),
    (function () {
      if (!$(".page-sidebar").length) return;
      var e = $(".accordion-menu li:not(.open) ul"),
        s = $(".accordion-menu li.active-page > a");
      e.hide();
      const o = document.querySelector(".page-sidebar .accordion-menu");

      $("body").hasClass("page-sidebar-collapsed") &&
        $(window).width() > 1350 &&
        a.destroy(),
        $(window).resize(function () {
          if ($(window).width() <= 1350) {
            const e = document.querySelector(".page-sidebar");
          }
        }),
        $(".accordion-menu li a").on("click", function (e) {
          var s = $(this).next("ul"),
            o = $(this).parent("li"),
            t = $(".accordion-menu > li.open");
          if (s.length)
            return $("body").hasClass("page-sidebar-collapsed") &&
              $(window).width() > 1350
              ? void e.preventDefault()
              : (o.hasClass("open")
                ? (s.slideUp(200), o.removeClass("open"))
                : // a.update()
                (t.length &&
                  ($(".accordion-menu > li.open > ul").slideUp(200),
                    t.removeClass("open")),
                  // a.update()
                  s.slideDown(200),
                  o.addClass("open")),
                // a.update()
                !1);
        }),
        $(".active-page > ul").length && s.click();
    })(),
    (function () {
      // $('[data-bs-toggle="popover"]').popover(),
      //   $('[data-bs-toggle="tooltip"]').tooltip();
      var e = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(e).forEach(function (e) {
        e.addEventListener(
          "submit",
          function (s) {
            e.checkValidity() || (s.preventDefault(), s.stopPropagation()),
              e.classList.add("was-validated");
          },
          !1
        );
      });
    })(),
    $("#activity-sidebar-toggle").on("click", function (e) {
      $("body").toggleClass("activity-sidebar-show"), e.preventDefault();
    }),
    $(".activity-sidebar-overlay").on("click", function (e) {
      $("body").toggleClass("activity-sidebar-show");
    });
  $(".page-content").on("click", function (e) {
    $(".profile-drop-menu").removeClass("show");
  });
  // $("#activity-sidebar-close").on("click", function (e) {
  //    
  //   $("body").removeClass("activity-sidebar-show");
  // });
}),
  $(window).on("load", function () {
    setTimeout(function () {
      $("body").addClass("no-loader");
    }, 1e3);
  });
